<template>
    <vx-card :title="title">
      <vs-tabs :value="activeTab">
        <vs-tab label="Waiting Execute">
          <Waiting></Waiting>
        </vs-tab>
        <vs-tab label="Executed">
          <Executed></Executed>
        </vs-tab>
        <vs-tab label="Cancel Execute">
          <CancelExecuted></CancelExecuted>
        </vs-tab>
      </vs-tabs>
    </vx-card>
  </template>
  <script> 
  import Waiting from "./_tab/waiting-tab.vue";
  import Executed from "./_tab/executed-tab.vue";
  import CancelExecuted from "./_tab/cancel-execute-tab.vue";
  export default {
    props: {
      active: {
        type: Number,
      },
    },
    components: {
      Waiting,
      Executed,
      CancelExecuted,
    },
    data() {
      return {
        title: "Stock Scrap Execute",
        activeTab : this.$route.query.tab ? this.$route.query.tab : 0
      };
    },
  };
  </script>
  