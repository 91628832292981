<template>
  <div>

    <div class="vx-row mt-10 mb-6">
      <div class="vx-col sm:w-1/5 w-full">
        <span>Warehouse</span>
      </div>
      <div class="vx-col sm:w-4/5 w-full">
        <div class="vx-col w-1/2">
          <multiselect
          :multiple="true" 
          :filterable="true" 
          :allow-empty="true"
          track-by="id"
          :custom-label="customLabelWarehouse"
          :options="optionWarehouse" 
          v-model="selectedWarehouse" 
          placeholder="ALL Warehouse" 
          >
          </multiselect>
        </div>
      </div>
    </div>
    
    <div class="vx-row mb-6 mt-3">
      <div class="vx-col sm:w-1/5 w-full">
        <span>Document date</span>
      </div>
      <div class="vx-col sm:w-4/5 w-full">
        <div class="vx-col w-1/2">
          <template>
              <datepicker
              name="date"
              :inline="false"
              v-model="date"
              :clearable="true"
              placeholder="Select Date">
              </datepicker>
          </template>
        </div>
      </div>
    </div>

    <div class="vx-row mb-6 mt-3">
      <div class="vx-col sm:w-1/5 w-full">
        <span>Posting date</span>
      </div>
      <div class="vx-col sm:w-4/5 w-full">
        <div class="vx-col w-1/2">
          <template>
              <datepicker
              name="posting_date"
              :inline="false"
              v-model="posting_date"
              :clearable="true"
              placeholder="Select Date">
              </datepicker>
          </template>
        </div>
      </div>
    </div>

    <div class="vx-row w-1/2 mb-6 mt-3">
      <div class="vx-col">
        <vs-button
          @click="onClickBtnFilter"
          size="medium"
          color="primary"
          type="gradient"
          icon-pack="feather"
          icon="icon-search"
          title="Search"
        >
          Filter
        </vs-button>
      </div>
    </div>

    <vs-table
      search
      stripe
      border
      description
      :sst="true"
      :data="table.data"
      :max-items="table.length"
      :total="table.total"
      @change-page="handleChangePage"
      @search="handleSearch"
      @sort="handleSort"
    >
      <template slot="header">
        <vs-dropdown vs-trigger-click class="cursor-pointer">
          <div
            class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
          >
            <span class="mr-2"
              >{{ this.table.start }} - {{ this.table.end }} of
              {{ this.table.total }}</span
            >
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item
              v-for="item in table.limits"
              :key="item"
              @click="handleChangelength(item)"
            >
              <span>{{ item }}</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </template>
      <template slot="thead">
        <vs-th></vs-th>
        <vs-th sort-key="code">Scrap</vs-th>
        <vs-th sort-key="name">Warehouse</vs-th>
        <vs-th sort-key="name">Document Date</vs-th>
        <!-- <vs-th sort-key="name">Posting Date</vs-th> -->
        <!-- <vs-th sort-key="name">Supplier</vs-th> -->
        <vs-th sort-key="name">Charge To</vs-th>
        <vs-th sort-key="name"># of SKU</vs-th>
        <vs-th sort-key="name">TOTAL Qty [UOM]</vs-th>
        <vs-th sort-key="name">Inventory Value</vs-th>
      </template>
      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td>
            <div class="vx-input-group flex">
              
              <vx-tooltip text="View">
              <vs-button
                class="ml-2"
                size="small"
                color="primary"
                icon-pack="feather"
                icon="icon-eye"
                @click="HandleView(tr.id)"
              ></vs-button>
              </vx-tooltip>

              <vx-tooltip text="Execute">
              <vs-button
                class="ml-2"
                size="small"
                color="warning"
                icon-pack="feather"
                icon="icon-check"
                @click="HandleExecute(tr)"
              ></vs-button>
              </vx-tooltip>

              
              <vx-tooltip text="Cancel">
                <vs-button
                  class="ml-2"
                  size="small"
                  color="danger"
                  icon-pack="feather"
                  icon="icon-delete"
                  @click="HandleDelete(tr.id)"
                ></vs-button>
              </vx-tooltip>
            </div>
          </vs-td>
          <vs-td :data="data[indextr].code">
            Code : {{ data[indextr].code }} <br>
            Type : {{ data[indextr].type }}
          </vs-td>
          <vs-td :data="data[indextr].warehouse_code">
            {{ data[indextr].warehouse_code }} - {{ data[indextr].warehouse_name }}
          </vs-td>
          <vs-td :data="data[indextr].date">
            {{ formatDate(data[indextr].date) }}
          </vs-td>
          <!-- <vs-td :data="data[indextr].posting_date">
            {{ formatDate(data[indextr].posting_date) }}
          </vs-td> -->
          <!-- <vs-td :data="data[indextr].supplier_code">
            {{ data[indextr].supplier_code }} - {{ data[indextr].supplier_name }}
          </vs-td> -->
          <vs-td :data="data[indextr].charge">
            {{ data[indextr].charge }}
          </vs-td>
          <vs-td :data="data[indextr].total_line">
            <div v-html="data[indextr].total_line"></div>
          </vs-td>
          <vs-td :data="data[indextr].quantity">
            {{ data[indextr].quantity }}
          </vs-td>
          <vs-td :data="priceFormat(data[indextr].total_map)">
            {{ priceFormat(data[indextr].total_map) }}
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <vs-pagination
      style="padding-top: 5px"
      :total="table.totalPage"
      v-model="setPage"
    />
    
    <vs-prompt 
      color="primary" 
      title="Confirmation" 
      @cancel="submitPromptConfirm = false" 
      @accept="putData"
      @close="closePrompt" 
      :accept-text="'Yes'"
      :active.sync="submitPromptConfirm">
      <div class="con-exemple-prompt">
        <div class="vx-row mb-12 mt-3">
          <div class="vx-col w-full">
            <span>Posting date</span>
          </div>
          <div class="vx-col w-full">
            <div class="vx-col">
              <template>
                  <datepicker
                  name="posting_date_execute"
                  :inline="false"
                  v-model="posting_date_execute"
                  :clearable="false"
                  :disabledDates="disabledDates"
                  placeholder="Select Date">
                  </datepicker>
              </template>
            </div>
          </div>
        </div>

      </div>
  </vs-prompt>
  </div>
</template>
<script>
import moment from "moment";
import Datepicker from "vuejs-datepicker";
export default {
  components: {
    Datepicker,
  },

  mounted() {
    this.getWarehouse();
  },

  data() {
    return {
      deleteId: null,
      table: this.tableDefaultState(),
      exportID: [],
      IDwasGenerate: [],
      showingData: true,
      selectAll: false,
      date: null,
      posting_date: null,
      documentDate: null,
      optionWarehouse: [],
      selectedWarehouse: [],
      cartStockScrap: [],
      IDItem: [],
      submitPromptConfirm: false,
      posting_date_execute: moment().format("YYYY-MM-DD"),
      disabledDates: {},
    };
  },
  methods: {
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    closePrompt() {
        this.submitPromptConfirm = false
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 0;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 0;
      this.getData();
    },
    handleClose() {
      this.getData();
      this.$emit("close");
    },
    putData() {
      let form = new FormData();
      form.append("posting_date", moment(this.posting_date_execute).format("YYYY-MM-DD"));
      
      this.$vs.loading();
      this.$http
        .put("/api/wms/v1/scrap/execute/" + this.id, form, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "Scrap has been executed",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            this.handleClose();
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    getWarehouse() {
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/master/warehouses", {
          params: {
            length: null,
            order: "name",
            sort: "asc",
            by_personal: 1
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionWarehouse = resp.data.records;
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getData() {
      const warehouseIds =this.selectedWarehouse.map(warehouse => {
              return warehouse.id;
          });
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/scrap/", {
          params: {
            length: this.table.length,
            page: this.table.page,
            search: this.table.search,
            order: this.table.order,
            sort: this.table.sort,
            request_date: this.date,
            posting_date: this.posting_date,
            status: 7,
            warehouse_id: warehouseIds,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.table.total = resp.data.total_record;
            this.table.totalPage = resp.data.total_page;
            this.table.totalSearch = resp.data.total_record_search;
            this.table.length = resp.data.total_record_per_page;
            this.table.data = resp.data.records;

            this.setStartEnd();
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    customLabelWarehouse({ code, name }) {
      return `${code} - ${name}`;
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;

      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;

      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }

      if (
        this.table.totalSearch < this.table.total &&
        this.table.search != ""
      ) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    onClickBtnFilter() {
      let params = {
        ...this.table,
      };

      if (this.date) {
        this.date = moment(this.date).format("YYYY-MM-DD");
      }
      if (this.posting_date) {
        this.posting_date = moment(this.posting_date).format("YYYY-MM-DD");
      }
      this.getData(params);
    },
    HandleView(id) {
      this.$router.push({
        name: "stock-scrap-view",
        query: { id: id, tab: 0, is_execute: 1 },
      });
      this.id = id;
      this.detail = true;
    },
    HandleExecute(data) {
      console.log("HandleExecute2", data)
      console.log("data.date", data.date)
      console.log("moment(data.date)", moment(data.date).format("YYYY-MM-DD"))
      this.disabledDates = {
        from: new Date(Date.now()),
        to: new Date(moment(data.date).format("YYYY-MM-DD"))
      }
      
      // this.posting_date_execute = data.posting_date
      this.submitPromptConfirm = true
      this.id = data.id;
      this.doucmentDate = data.date
      this.detail = true;
    },
    HandleDelete(id) {
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: "Warning",
        text: "Are you sure to cancel this data?",
        accept: () => {
          this.$vs.loading();
          this.$http
            .put("api/wms/v1/scrap/cancel-execute/" + id)
            .then((resp) => {
              if (resp.code == 200) {
                this.$vs.notify({
                  title: "Success",
                  text: "Data cancel successfully",
                  color: "success",
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-check",
                });
              }
              this.$vs.loading.close();
              this.getData();
            });
        },
      });
    },
    customLableWarehouse({ code, name }) {
      return `${code} - ${name}`;
    },
  },
  watch: {
    detail() {
      this.getData();
    },
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },    
    formatDate: () => {
        return (val) => {
            const momentDate = moment.utc(val);
            return momentDate.format('DD MMM YYYY');
        };
    },
  },
};
</script>
